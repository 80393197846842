/**
 * Index Page
 *
 * Author : vijay verma
 * Created : 28-Jan-2020
 */

import React from "react"
import { Link, graphql } from "gatsby"

import Sushi from "../components/sushi"
import Layout from "../components/layout-dashboard"
import SEO from "../components/seo"

class GetStarted extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="All posts" />
        <section className="dash-wrap">
          <div className="side-nav">
            <ul className="top-menu">
              <li>
                <a href="#">Geting started</a>
                <ul className="second-menu">
                  <li><a href="/get-started">Introduction</a></li>
                  <li className="active"><a href="#">Principle</a></li>
                </ul>
              </li>

              <li>
                <a href="#">Foundations</a>
                <ul className="second-menu">
                  <li><a href="#">Color</a></li>
                  <li><a href="#">Typography</a></li>
                  <li><a href="#">Introduction</a></li>
                </ul>
              </li>
              <li>
                <a href="#">Voice & Tone</a>
              </li>
              <li>
                <a href="#">Brands</a>
              </li>
              <li>
                <a href="#">Blog</a>
              </li>
            </ul>

          </div>


          <div className="content-area">
            <div className="contents">
            <h1 className="content-head">Principle</h1>
            <p>Core principles of Sushi Design System–</p>
            <h2>User Centricity</h2>
            <p>We want to ensure that every interaction our users have with our product is a positive one and solves for something, thereby building a strong connection with our brand or product across platforms.</p>
            <h2>Inclusivity</h2>
            <p>Our products serve everyone, from teens who are still in college to people not too savvy with modern-day technology. We want to design and build our platform which is viable by as many people as possible, regardless of ability, age and geography.</p>
            <h2>User Centricity</h2>
            <p>We deliver delight and satisfaction through a clean and focused experience every time. By relying on existing, common design patterns that are intuitive in form, function and flow, we ensure a more user-friendly interface for all.</p>
            <h2>Consistency</h2>
            <p>We create familiarity and strengthen intuition by applying the same solution to problems across our platforms.</p>
            </div>
          </div>

        </section>
      </Layout>
    )
  }
}

export default GetStarted

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
